<template>
  <div class="contact-us">
    <h1 class="contact-dialog__title">Contact us</h1>
    <p class="contact-dialog__text">Fill out the form and a product expert will be in touch soon.</p>
    <p class="contact-dialog__text mb-6">
      If you are a patient and need to request a doctor visit, please
      <router-link to="/auth/signin">get started here</router-link>
      .
    </p>
    <span class="heading-5 p-schedule-visit__content__visit-reason__title">
      {{ $t("landing.contact.name") }}
      <span class="p-schedule-visit__content__required">*</span>
    </span>
    <BaseInput
      v-model="name"
      :fullWidth="true"
      :height="$vuetify.breakpoint.xsOnly ? 48 : 60"
      :placeholder="$t('landing.contact.namePlaceholder')"
      class="my-2"
      fieldName="name"
      outlined
      required
    />
    <span class="heading-5 p-schedule-visit__content__visit-reason__title">
      {{ $t("landing.contact.email") }}
      <span class="p-schedule-visit__content__required">*</span>
    </span>
    <BaseInput
      v-model="email"
      :fullWidth="true"
      :height="$vuetify.breakpoint.xsOnly ? 48 : 60"
      :placeholder="$t('landing.contact.emailPlaceholder')"
      class="my-2"
      fieldName="email"
      outlined
      required
    />
    <span class="heading-5 p-schedule-visit__content__visit-reason__title">
      {{ $t("landing.contact.comments") }}
    </span>
    <Textarea
      v-model="comments"
      :fullWidth="true"
      :placeholder="$t('landing.contact.commentsPlaceholder')"
      class="my-2 contact-us__comment"
      fieldName="comments"
      outlined
    />

    <PrimaryButton
      :disabled="isButtonDisabled"
      :fullWidth="true"
      :loading="buttonLoading"
      :text="$t('landing.contact.submit')"
      className="contact-dialog__submit primary-btn__blue"
      size="large"
      @onClick="submit"
    />
    <p class="contact-us__footer">
      Need Help? <a :href="telContactPhoneNumber"> {{ contactPhoneNumber }} </a>
    </p>
  </div>
</template>

<script>
import { mapState } from "pinia";

import { ContactUsApi } from "@/api/contactUs";
import BaseInput from "@/components/uikit/BaseInput";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import Textarea from "@/components/uikit/Textarea";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAppStore } from "@/pinia-store/app";

export default {
  name: "ContactUsContent",
  components: {
    BaseInput,
    Textarea,
    PrimaryButton,
  },
  data() {
    return {
      name: "",
      email: "",
      comments: "",
      buttonLoading: false,
    };
  },
  computed: {
    ...mapState(useAppStore, ["contactPhoneNumber", "telContactPhoneNumber"]),
    isButtonDisabled() {
      return !this.name || !this.email;
    },
  },
  methods: {
    clearForm() {
      this.name = "";
      this.email = "";
      this.comments = "";
      this.buttonLoading = false;
    },
    emitClose() {
      this.$emit("close");
    },
    async submit() {
      if (!this.name || !this.email) return;
      this.buttonLoading = true;
      try {
        await ContactUsApi.send({ name: this.name, email: this.email, comments: this.comments });
        this.emitClose();
        this.clearForm();
        snackBarEventBus.$emit(snackBarEventName, {
          message: this.$t("landing.contact.thankYou"),
          type: "success",
        });
      } catch (err) {
        console.error(err);
        if (err && err.response && err.response.status === 409) {
          snackBarEventBus.$emit(snackBarEventName, {
            message: this.$t("general.errors.slotConflict"),
            type: "error",
          });
        }
        snackBarEventBus.$emit(snackBarEventName, {
          message: (err && err.response && err.response.data && err.response.data.message) || err,
          type: "error",
        });
      }
    },
  },
};
</script>
<style lang="scss">
.contact-us__comment textarea {
  min-height: 100px;
  height: auto;
}
</style>
<style lang="scss" scoped>
.contact-us {
  background: #fff;
  position: relative;
  padding: 30px 25px;

  &__title {
    font-weight: bold;
    font-size: 32px;
    @media (max-width: 768px) {
      font-size: 28px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 32px;
    margin: 10px 0;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__submit {
    text-transform: initial;
    width: 100%;
    letter-spacing: initial;
    font-size: 18px;
    height: 46px !important;
    margin-top: 25px;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 100px;
    width: 100%;
    background-color: #d2d3e1;
    height: 1px;
    left: 0;
  }

  &__footer {
    padding-top: 29px;
    margin: 0 auto;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #33343e;

    a {
      text-decoration: none;
      color: var(--primary);
      font-weight: 700;
    }
  }
}
</style>
